var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      title: _vm.$t("Criar novo depósito"),
      show: _vm.showSidebar,
      fetching: _vm.loading,
      saving: _vm.saving,
      width: "500px",
    },
    on: { save: _vm.saveBankDeposit, hidden: _vm.onCloseSidebar },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "formBankDeposit",
                attrs: { name: "formBankDeposit" },
                on: { submit: _vm.saveBankDeposit },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-store-combo", {
                          attrs: {
                            id: "bank-deposit-store",
                            "select-single-option": false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.fetchAccount(
                                _vm.formBankDeposit.storeId
                              )
                            },
                          },
                          model: {
                            value: _vm.formBankDeposit.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formBankDeposit, "storeId", $$v)
                            },
                            expression: "formBankDeposit.storeId",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.safeBoxAccount
                      ? _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bank-deposit-from-account",
                                value: _vm.safeBoxAccount.name,
                                type: "label",
                                label: _vm.$t("Saída da conta"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.mainAccount
                      ? _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bank-deposit-to-account",
                                value: _vm.mainAccount.name,
                                type: "label",
                                label: _vm.$t("Entrada na conta"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.safeBoxAccount
                      ? _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              staticClass: "required",
                              attrs: {
                                id: "bank-deposit-balance",
                                type: "label",
                                filter: "currency",
                                label: _vm.$t("Saldo da Conta Cofre"),
                              },
                              model: {
                                value: _vm.safeBoxAccount.balance,
                                callback: function ($$v) {
                                  _vm.$set(_vm.safeBoxAccount, "balance", $$v)
                                },
                                expression: "safeBoxAccount.balance",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "bank-deposit-value",
                            type: "text-number",
                            currency: "R$",
                            precision: 2,
                            label: _vm.$t("Valor"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.formBankDeposit.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.formBankDeposit, "value", $$v)
                            },
                            expression: "formBankDeposit.value",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "bank-deposit-deposit-date",
                            type: "datepicker",
                            label: _vm.$t("Data do lançamento"),
                            instruction: _vm.$t(
                              "Informar apenas se a data do depósito for diferente da data atual"
                            ),
                          },
                          model: {
                            value: _vm.formBankDeposit.depositDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formBankDeposit, "depositDate", $$v)
                            },
                            expression: "formBankDeposit.depositDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "bank-deposit-document-type",
                            label: _vm.$t("Tipo do documento"),
                            type: "vue-select",
                            options: _vm.documentTypeOptions(),
                          },
                          model: {
                            value: _vm.formBankDeposit.documentType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formBankDeposit, "documentType", $$v)
                            },
                            expression: "formBankDeposit.documentType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-only-number-input", {
                          attrs: {
                            id: "bank-deposit-document-number",
                            label: _vm.$t("N.º Documento"),
                            required: "",
                          },
                          model: {
                            value: _vm.formBankDeposit.documentNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formBankDeposit,
                                "documentNumber",
                                $$v
                              )
                            },
                            expression: "formBankDeposit.documentNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "bank-deposit-description",
                            type: "text",
                            label: _vm.$t("Descrição"),
                          },
                          model: {
                            value: _vm.formBankDeposit.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.formBankDeposit, "description", $$v)
                            },
                            expression: "formBankDeposit.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }