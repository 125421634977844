<template>
  <e-sidebar
    :title="$t('Criar novo depósito')"
    :show="showSidebar"
    :fetching="loading"
    :saving="saving"
    width="500px"
    @save="saveBankDeposit"
    @hidden="onCloseSidebar"
  >
    <template #content>
      <FormulateForm
        ref="formBankDeposit"
        name="formBankDeposit"
        @submit="saveBankDeposit"
      >
        <b-row>
          <b-col md="12">
            <!-- :select-single-option="false" é necessário para evitar loop quando usuário visualiza apenas 1 loja -->
            <e-store-combo
              id="bank-deposit-store"
              v-model="formBankDeposit.storeId"
              :select-single-option="false"
              @input="fetchAccount(formBankDeposit.storeId)"
            />
          </b-col>
          <b-col
            v-if="safeBoxAccount"
            md="12"
          >
            <FormulateInput
              id="bank-deposit-from-account"
              :value="safeBoxAccount.name"
              type="label"
              :label="$t('Saída da conta')"
            />
          </b-col>
          <b-col
            v-if="mainAccount"
            md="12"
          >
            <FormulateInput
              id="bank-deposit-to-account"
              :value="mainAccount.name"
              type="label"
              :label="$t('Entrada na conta')"
            />
          </b-col>
          <b-col
            v-if="safeBoxAccount"
            md="12"
          >
            <FormulateInput
              id="bank-deposit-balance"
              v-model="safeBoxAccount.balance"
              type="label"
              filter="currency"
              :label="$t('Saldo da Conta Cofre')"
              class="required"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="bank-deposit-value"
              v-model="formBankDeposit.value"
              type="text-number"
              currency="R$"
              :precision="2"
              :label="$t('Valor')"
              class="required"
              validation="required"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="bank-deposit-deposit-date"
              v-model="formBankDeposit.depositDate"
              type="datepicker"
              :label="$t('Data do lançamento')"
              :instruction="$t('Informar apenas se a data do depósito for diferente da data atual')"
            />
          </b-col>

          <b-col md="12">
            <FormulateInput
              id="bank-deposit-document-type"
              v-model="formBankDeposit.documentType"
              :label="$t('Tipo do documento')"
              type="vue-select"
              :options="documentTypeOptions()"
            />
          </b-col>
          <b-col md="12">
            <e-only-number-input
              id="bank-deposit-document-number"
              v-model="formBankDeposit.documentNumber"
              :label="$t('N.º Documento')"
              required
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="bank-deposit-description"
              v-model="formBankDeposit.description"
              type="text"
              :label="$t('Descrição')"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { banks, financialDomain } from '@/mixins'
import { ESidebar, EStoreCombo } from '@/views/components'
import EOnlyNumberInput from '@/views/components/inputs/EOnlyNumberInput.vue'

const getInitialForm = () => ({
  storeId: null,
  value: null,
  depositDate: null,
  description: null,
  documentType: 'RECIBO',
  documentNumber: null,
})

export default {
  components: { BRow, BCol, ESidebar, EStoreCombo, EOnlyNumberInput },

  mixins: [banks, financialDomain],

  data() {
    return {
      showSidebar: false,
      saving: false,
      loading: false,
      formBankDeposit: getInitialForm(),
      safeBoxAccount: null,
      mainAccount: null,
    }
  },

  computed: {},

  watch: {},

  methods: {
    async onShowSidebar() {
      try {
        this.resetValues()
        this.showSidebar = true
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.loading = false
      }
    },

    onCloseSidebar() {
      this.showSidebar = false
      this.resetValues()
      this.$formulate.resetValidation('formBankDeposit')
      // this.$refs.formBankDeposit.resetValidation()
    },

    resetValues() {
      this.safeBoxAccount = null
      this.mainAccount = null
      this.formBankDeposit = getInitialForm()
    },

    async fetchAccount(storeId) {
      if (storeId) {
        try {
          this.loading = true
          const result = await this.$http.get(
            `/api/financial/checking-accounts/by-store/${storeId}`
          )
          const safeBoxAccount = result.data.results.find(account => account.isSafeBox)
          if (!safeBoxAccount) {
            this.showError({
              message: this.$t(
                'Não foi encontrada a Conta Cofre desta loja. Entre em contato com a administração.'
              ),
            })
          }
          this.safeBoxAccount = safeBoxAccount

          const mainAccount = result.data.results.find(account => account.isMain)
          if (!mainAccount) {
            this.showError({
              message: this.$t(
                'Não foi encontrada a Conta Principal desta loja. Entre em contato com a administração.'
              ),
            })
          }
          this.mainAccount = mainAccount
        } catch (error) {
          this.showError({ error })
        } finally {
          this.loading = false
        }
      } else {
        this.safeBoxAccountBalance = null
      }
    },

    async saveBankDeposit() {
      try {
        this.$refs.formBankDeposit.showErrors()
        if (this.$refs.formBankDeposit.isValid) {
          this.saving = true

          await this.$http({
            url: '/api/financial/bank-deposit',
            method: 'POST',
            data: this.formBankDeposit,
          })
          this.showSidebar = false
          this.showSuccess({ message: this.$t('Depósito incluído com sucesso') })
          this.$emit('save', this.formBankDeposit)
        } else {
          this.showInvalidDataMessage()
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style></style>
